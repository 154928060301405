import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import Stack from "./stack"
import Image from "./image"
import Columns from "./columns"
import Column from "./column"
import Heading from "./heading"

const Comment = ({ children, title, image, reverse, space, collapse, alt }) => {
  return (
    <Columns space={space} collapse={collapse} reverse={reverse}>
      {image && (
        <Column width="content">
          <Box
            sx={{
              borderWidth: 8,
              borderStyle: "solid",
              borderColor: "background",
              borderRadius: "100%",
              overflow: "hidden",
              width: [12, 16, 24],
              height: [12, 16, 24],
            }}
          >
            <Image className="comment-image" image={image} alt={alt} />
          </Box>
        </Column>
      )}
      <Column>
        <Box
          sx={{
            borderRadius: "lg",
            position: "relative",
            bg: "muted",
            p: 6,
          }}
        >
          <Stack space={3}>
            {title && <Heading as="p">{title}</Heading>}
            {children}
          </Stack>
        </Box>
      </Column>
    </Columns>
  )
}

Comment.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  collapse: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  reverse: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

Comment.defaultProps = {
  alt: "",
  space: 3,
}

export default Comment
